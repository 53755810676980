import { NavLink } from "react-router-dom";
import "./AlbumCard.css";

interface AlbumProps{
    name: string;
    base: string;
}

export function AlbumCard(props:AlbumProps): JSX.Element {
    return (
        <div className="AlbumCard">
			<div className="name"><NavLink to={"/album/" +props.name}>{props.name}</NavLink></div>
        </div>
    );
}
