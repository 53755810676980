import axios from "axios";
import {MyImage} from "../Models/Image";

class ImagesService{

    public async getAlbums(){
        
        const response = (await axios.get("/getalbums")).data;
        return response;
    }

    public async getAlbumImages(albumName: string):Promise<MyImage[]>{

        return (await axios.get("/getalbums/path/" + albumName)).data;
    }

    public async getImage(id: number):Promise<string>{

        return (await axios.get("/image/" + id)).data;
    }

    public async deleteImage(id: number):Promise<string>{
        return (await axios.delete("/image/" + id)).data;
    }

    public async generateImage(prompt: string, number: number, face:string){
        return (await axios.post<string>("/v2/createimage", null, { params: {prompt, number, face}})).data;
    }
}

let imagesService = new ImagesService();
export default imagesService;