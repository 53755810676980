import "./Docs.css";

export function Docs(): JSX.Element {
    return (
        <div className="Docs">
			<h1>My Personal Documentation</h1>
            <p>Below are a few of my personal summaries in <code>docx</code> format to help you along the way.</p>

            <h2>☕ Java Basics</h2>
            <p>TBA</p>

            <h2>𝄜 SQL</h2>
            <a href="/sql.docx">SQL Keywords & Examples</a>

            <h2>🍃 Spring Basics</h2>
            <a href="/Spring Basics.docx">Spring Basics Annotations</a>

            <h2>🛢 Spring JPA</h2>
            <a href="/Spring JPA.docx">Spring JPA Annotations</a>

            <h2>🌐 Spring Web</h2>
            <a href="/Spring Web.docx">Spring Web Annotations</a>
        </div>
    );
}
