import { useEffect, useState } from "react";
import "./Albums.css";
import imagesService from "../../../Services/ImagesService";
import { AlbumCard } from "../../ImagesArea/AlbumCard/AlbumCard";

export function Albums(): JSX.Element {
    const [albums, setAlbums] = useState<string[]>();
    useEffect(()=>{
        imagesService.getAlbums().then(result=>setAlbums(result)).catch(err=>alert(err));
    }, [])
    

    return (
        <div className="Albums">
            <div className="albums">
			    {albums?.map((al,i) => <AlbumCard key={i} name={al} base=""/>)}
            </div>
        </div>
    );
}
