import "./Home.css";

export function Home(): JSX.Element {

    return (
        <div className="Home">
            <div className="content">
                <section>
                    <h2>About Me</h2>
                    <p>Hey there, I'm Nir Gal, your enthusiastic Java Fullstack Lecturer! 👨‍🏫 <br />With a passion for coding and a love for teaching, I specialize in Java programming, Java Spring framework, and frontend development using React. My mission is to demystify the world of code and empower you to create amazing web applications!</p>
                    <p>With years of hands-on experience in Java and the Spring framework, I'll guide you through the intricacies of backend development, making complex concepts feel like a walk in the park. On the frontend, we'll dive into the world of React, crafting dynamic user interfaces that leave a lasting impression.</p>
                    <p>Whether you're a beginner taking your first steps into the coding universe or an experienced developer looking to level up your skills, you're in the right place. Join me on this exciting coding journey, and let's turn your dreams of becoming a Java and React wizard into reality!</p>
                    <img src="images/nir_pixel.png" alt="Me..." />
                </section>
            </div>
            <script src="utils/script.js"></script>
        </div>
    );
}
