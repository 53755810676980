import "./GenerateImage.css";
import React, {useState} from "react";
import {
    FormControl,
    TextField,
    Button,
    TextareaAutosize,
    Select,
    InputLabel,
    MenuItem
} from "@material-ui/core";
import imagesService from "../../../Services/ImagesService";

export function GenerateImage(): JSX.Element {

    const [prompt, setPrompt] = useState<string>("");
    const [number, setNumber] = useState<number>(1);
    const [face, setFace] = useState<string>("");
    const [result, setResult] = useState<string>("");


    function generateImages() {
        //console.log("params: ", prompt, number, face)
        imagesService.generateImage(prompt, number, face)
            .then(res=>setResult(res))
            .catch(err=>alert(err));
    }

    return (
        <div className="GenerateImage">
			<h1>Generate 🧬</h1>

            <TextareaAutosize aria-label="Prompt" placeholder="Prompt" minRows={6} onChange={(e)=>{setPrompt(e.target.value)}}/>
            <FormControl>
                <TextField type={"number"} value={number} label={"Number of images"} onChange={(e)=>{setNumber(+e.target.value)}}></TextField>
            </FormControl>
            <FormControl>
                <InputLabel id={"select_face_label"}>Face (Optional)</InputLabel>
                <Select onChange={(e)=>setFace(e.target.value+"")} value={""}>
                    <MenuItem value={""}>None</MenuItem>
                    <MenuItem value={"Inbal"}>Inbal</MenuItem>
                    <MenuItem value={"Michal"}>Michal</MenuItem>
                    <MenuItem value={"Adi"}>Adi</MenuItem>
                </Select>
            </FormControl>
            <FormControl>
                <Button onClick={generateImages}>Generate</Button>
            </FormControl>
            <br/>
            {result && <div>{result}</div>}
        </div>
    );
}
