import "./MyAPIs.css";

export function MyAPIs(): JSX.Element {
    return (
        <div className="MyAPIs">
            <h1>My API Endpoints</h1>
            <p>
                Here is a list of the endpoints you can use with your REST application or client code.<br/>
                Each endpoint uses its own PARAMETERS so take a look at the information beneath each one.
            </p>
            <p>You can also check the Swagger UI page here: <a href="https://nirgalweb.com/swagger-ui.html">swagger</a></p>
            <div className="endpoint">
                <h2>GET /api/quotes</h2>
                <p>Description: Returns a random famous quote</p>
                <table>
                    <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                </table>
                <p><strong>Returns:</strong> A string.</p>
                <p><strong>Example:</strong> <a href="https://nirgalweb.com/api/quotes">https://nirgalweb.com/api/quotes</a></p>
            </div>

            <div className="endpoint">
                <h2>GET /api/factorial</h2>
                <p>Description: Receives a number and returns the number's factorial</p>
                <table>
                    <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td><span className="code">number</span></td>
                        <td>integer</td>
                        <td>The number to send.</td>
                    </tr>
                </table>
                <p><strong>Returns:</strong> A number.</p>
                <p><strong>Example:</strong> <a href="https://nirgalweb.com/api/factorial?number=5">https://nirgalweb.com/api/factorial?number=5</a></p>
            </div>

            <div className="endpoint">
                <h2>GET /api/count</h2>
                <p>Description: Receives a text and a subtext and returns how many times was the subtext found in
                    text</p>
                <table>
                    <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td><span className="code">text</span></td>
                        <td>string</td>
                        <td>Big text.</td>
                    </tr>
                    <tr>
                        <td><span className="code">subtext</span></td>
                        <td>string</td>
                        <td>Smaller text.</td>
                    </tr>
                </table>
                <p><strong>Returns:</strong> A number.</p>
                <p><strong>Example:</strong> <a href="https://nirgalweb.com/api/count?text=hello&subtext=lo">https://nirgalweb.com/api/count?text=hello&subtext=lo</a></p>
            </div>

            <div className="endpoint">
                <h2>GET /api/rich/{'{name}'}</h2>
                <p>Description: Receives a billionaire name and return their rank</p>
                <table>
                    <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td><span className="code">name</span></td>
                        <td>PATH</td>
                        <td>Name of billionaire, use hyphen (-) between first nad last name.</td>
                    </tr>
                </table>
                <p><strong>Returns:</strong> rank info as JSON.</p>
                <p><strong>Example:</strong> <a href="https://nirgalweb.com/api/rich/bill-gates">https://nirgalweb.com/api/rich/bill-gates</a></p>
            </div>

            <div className="endpoint">
                <h2>GET /api/richbydate/{'{date}'}</h2>
                <p>Description: Receives a date and return billionaires ranking of that date</p>
                <table>
                    <tr>
                        <th>Parameter</th>
                        <th>Type</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td><span className="code">date</span></td>
                        <td>PATH</td>
                        <td>Date of rank, use template yyyy-MM-dd.</td>
                    </tr>
                </table>
                <p><strong>Returns:</strong> rank info as JSON.</p>
                <p><strong>Example:</strong> <a href="https://nirgalweb.com/api/richbydate/2024-01-01">https://nirgalweb.com/api/richbydate/2024-01-01</a></p>
            </div>
        </div>
    );
}
