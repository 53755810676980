import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Layout } from './Components/LayoutArea/Layout/Layout';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Layout />
);