import {NavLink, useNavigate} from "react-router-dom";
import "./Header.css";
import {useEffect, useState} from "react";
import imagesService from "../../../Services/ImagesService";

export function Header(): JSX.Element {

    function goToLogin() {
        window.location.href="http://nirgalweb.com/login";
    }

    return (
        <div className="Header">
            <NavLink to={"home"}>
                <img src={'/logo.png'} title="Home" alt="Logo"/>
            </NavLink>
            <div>
                <h1 onDoubleClick={goToLogin}>Welcome to Nir Gal Web</h1>
                <menu>
                    {/*
                        <NavLink to={"/generate"}>Generate</NavLink>
                        <NavLink to={"/albums"}>Albums</NavLink>
                    */}
                    <NavLink to={"home"}>Home</NavLink>
                    <NavLink to={"docs"}>Documentations</NavLink>
                    <NavLink to={"apis"}>My APIs</NavLink>
                </menu>
            </div>
            <aside></aside>
        </div>
    );
}
