import {HashRouter} from "react-router-dom";
import { Header } from "../Header/Header";
import "./Layout.css";
import { Routing } from "../Routing/Routing";

export function Layout(): JSX.Element {

    return (
        <div className="Layout">

            <HashRouter>
			<header>
                <Header/>
            </header>
            <main>
                <Routing/>
            </main>
            </HashRouter>
        </div>
    );
}
