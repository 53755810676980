import { Navigate, Route, Routes } from "react-router-dom";
import "./Routing.css";
import { Home } from "../Home/Home";
import { AlbumDetails } from "../../ImagesArea/AlbumDetails/AlbumDetails";
import { Albums } from "../Albums/Albums";
import {GenerateImage} from "../../ImagesArea/GenerateImage/GenerateImage";
import {Docs} from "../../TeachingArea/Docs/Docs";
import {MyAPIs} from "../../TeachingArea/MyAPIs/MyAPIs";

export function Routing(): JSX.Element {
    return (
        <div className="Routes boxed">
			<Routes>
                <Route path="home" Component={Home} />
                <Route path="albums" Component={Albums} />
                <Route path="generate" Component={GenerateImage} />
                <Route path="docs" Component={Docs} />
                <Route path="apis" Component={MyAPIs} />
                <Route path="album/:albumName" element={<AlbumDetails />} />

                {/* Default Route - second way */}
                <Route path="/" element={<Navigate to="/home" />} />
            </Routes>
        </div>
    );
}
