import { NavLink, useParams } from "react-router-dom";
import "./AlbumDetails.css";
import React, { useEffect, useState } from "react";
import imagesService from "../../../Services/ImagesService";
import { MyImage } from "../../../Models/Image";
import {Dialog, IconButton, ImageList, ImageListItem, ImageListItemBar} from "@material-ui/core";


export function AlbumDetails(): JSX.Element {
    const {albumName} = useParams();
    const [allImages, setAllImages] = useState<MyImage[]>([]);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");

    useEffect(()=>{
        imagesService.getAlbumImages(albumName!)
                .then(newImages => {
                    setAllImages( [...newImages] );
                })
                .catch(err=>alert(err));
    }, [albumName]);


    /*
    const makeHandleOnClickItem = (id: number) => (event: React.MouseEvent<HTMLDivElement>) => {
        imagesService.getImage(id)
                .then(image=>console.log(image)) // change to a dialog showing image!!!!
                .catch(err=>alert(err));
    };
     */

    const handleClose = () => {
        setOpen(false);
    };

    function handleImage(id: number): void {
        imagesService.getImage(id).then(img => {setImage(img); setOpen(true);}).catch(err=>alert(err));
    }

    function deleteImage(id: number) {
        imagesService.deleteImage(id).then(()=>{
            // remove image from gallery
            //const objWithIdIndex = allImages.findIndex((obj) => obj.id === id);
            //allImages.splice(objWithIdIndex, 1);

        }).catch(err=>alert(err));
    }

    return (
        <div className="AlbumDetails">
			
            <h2><NavLink to={"/home"}>Home</NavLink>/{albumName}</h2>
            
            
            {/*allImages.map(img => <img src={"data:image/png;base64," + img.thumbnail}  key={img.id} onClick={makeHandleOnClickItem(img.id)}/>)*/}
            <ImageList className="container" cols={5} rowHeight={350}>
                {allImages.map(img =>
                    <ImageListItem key={img.id} className="gridImage">
                        <img
                        src={"data:image/png;base64," + img.thumbnail}
                        alt=""
                        onClick={() => handleImage(img.id)}
                        loading="lazy"
                        />
                        <ImageListItemBar title={""} actionIcon={
                            <IconButton onClick={()=> deleteImage(img.id)}><span style={{cursor: "pointer"}}>X</span></IconButton>
                        } />

                  </ImageListItem>
                )}
            </ImageList>
            <Dialog
                className="modal"
                open={open}
                onClose={handleClose}
                scroll="paper">
                <img 
                    src={image}
                    alt=""
                />
            </Dialog>
        </div>
    );
}
